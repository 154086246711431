import type React from 'react'
import Button, { EButtonType } from 'src/ui/Button'
import ClearIcon from '../../../assets/images/svg/clear.svg'
import type FiltersStore from 'src/models/FiltersStore'
import { observer } from 'mobx-react'

type ClearFiltersButtonProps<T extends Record<string, unknown>> = {
  /**
   * Specific filter store for your filter
   */
  filtersStore: FiltersStore<T>
  /**
   * By default onClick action will clear all the filters, if any additional action needs to be performed
   * when clearing all filters use this prop, it will come after resetting filters
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

/**
 * Clear active filters button, filtersStore should be provided, every page with filters has one
 *
 * The button will not be rendered if no filters are active
 */
const ClearFiltersButton = observer(
  <T extends Record<string, unknown>>({
    filtersStore,
    onClick
  }: ClearFiltersButtonProps<T>) => {
    if (filtersStore.activeFilterCount < 1) return null

    return (
      <Button
        icon={<ClearIcon style={{ marginRight: 3 }} />}
        title={'Clear'}
        onClick={(event) => {
          filtersStore.resetFilters()
          if (onClick !== undefined) {
            onClick(event)
          }
        }}
        buttonType={EButtonType.LINK}
      />
    )
  }
)
export default ClearFiltersButton
