import type Store from './Store'
import { makeAutoObservable, runInAction } from 'mobx'
import TwoFactorAuthStore from './TwoFactorAuthStore'

export type TLoginPayload = {
  login: string
  password: string
  tfa_code?: string
}

export type TUser = {
  id: number
  email: string
  projects: string[]
  lang: string
  tfa_enabled: boolean
}

type TFAConfirmation = {
  message: '2FA required'
  temp_session_key: string
}

class UserStore {
  public user: TUser = {
    email: '',
    id: 0,
    lang: '',
    projects: [],
    tfa_enabled: false
  }

  public isLoading = false
  public tfa: TwoFactorAuthStore
  public loginTFARequired = false
  public tfaStatusChecked = false
  public isTFAEnabled = false
  public tempSessionKey: null | string = null

  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<UserStore, 'rootStore'>(this, { rootStore: false })
    this.tfa = new TwoFactorAuthStore(rootStore)
  }

  async getUserInfo(): Promise<void> {
    this.isLoading = true
    const response = await this.rootStore.api.get<TUser>('/jsapi/user/info')

    if (response !== undefined) {
      runInAction(() => {
        this.setUser(response)
        this.isLoading = false
      })
    }
  }

  async businessRegister(values: {
    company_industry: string
    company_name: string
    company_website: string
    country_of_incorporation: string
    email: string
    fullname: string
    message: string
  }): Promise<void> {
    return await this.rootStore.api.post('/jsapi/business-sign-up', {
      ...values
    })
  }

  async login({ login, password }: TLoginPayload) {
    const response = await this.rootStore.api.post<TUser | TFAConfirmation>(
      'jsapi/v2/user/login',
      {
        login,
        password
      }
    )

    if (response === undefined) {
      return undefined
    }

    if ('email' in response) {
      runInAction(() => {
        this.setUser(response)
      })
    }

    return response
  }

  async TFALogin({
    tfa_code,
    tempSessionKey
  }: {
    tfa_code: string
    tempSessionKey: string
  }) {
    const response = await this.rootStore.api.post<TUser>(
      '/jsapi/v2/user/tfa',
      {
        tfa_code,
        temp_session_key: tempSessionKey
      }
    )
    if (response !== undefined) {
      runInAction(() => {
        this.setUser(response)
      })
    }
  }

  async logout(): Promise<void> {
    await this.rootStore.api.get('/jsapi/user/logout')
  }

  setUser(userObject: TUser): void {
    this.user = userObject
    this.isTFAEnabled = userObject.tfa_enabled
  }

  async resetPasswordUsingEmail(
    email: string
  ): Promise<{ message: string } | undefined> {
    return await this.rootStore.api.post<{ message: string }>(
      'jsapi/user/password/reset',
      { email }
    )
  }

  // Confirm that a user has requested a password reset and went through a link
  async resetPasswordConfirmation(resetCode: string): Promise<boolean> {
    const response = await this.rootStore.api.get<{ message: string }>(
      'jsapi/user/password/reset-confirmation',
      { code: resetCode }
    )
    if (response !== undefined) {
      return true
    }
    return false
  }

  async createNewPassword(
    new_password: string,
    re_new_password: string,
    code: string
  ): Promise<boolean> {
    const response = await this.rootStore.api.post<{ message: string }>(
      'jsapi/user/password/reset-confirmation',
      {
        new_password,
        re_new_password,
        code
      },
      { title: 'Password change', content: 'Password successfully changed!' }
    )
    if (response !== undefined) {
      return true
    }
    return false
  }

  async profilePasswordChange({
    new_password,
    re_new_password,
    old_password,
    tfa_code
  }: {
    new_password: string
    re_new_password: string
    old_password: string
    tfa_code: string
  }): Promise<string | undefined> {
    const response = await this.rootStore.api.post<{ message: string }>(
      'jsapi/user/password/change',
      {
        new_password,
        re_new_password,
        old_password,
        tfa_code
      },
      { title: 'Password change', content: 'Password successfully changed!' }
    )
    if (response !== undefined) {
      return response.message
    }
    return undefined
  }
}

export default UserStore
