import { type PropsWithChildren } from 'react'
import styles from './NavigationWrapper.module.scss'

type NavigationWrapperProps = {
  test?: string
} & PropsWithChildren

const NavigationWrapper = ({ children }: NavigationWrapperProps) => {
  return <div className={styles.NavigationWrapper}>{children}</div>
}
export default NavigationWrapper
