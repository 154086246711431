/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Col, Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import NavigationWrapper from 'src/components/Navigation/NavigationWrapper/NavigationWrapper'
import NavButton from 'src/components/Navigation/NavButton/NavButton'

const Filters = observer(() => {
  const store = useStore()
  const params = useParams()

  // Change filter action
  useEffect(() => {
    if (params.page) {
      let value = ''
      if (params.page === 'all') {
        value = ''
      } else if (params.page === 'crypto') {
        value = 'crypto'
      } else {
        value = 'fiat'
      }

      store.balancesStore.filters.setFilter('balance_type', value)
    }
  }, [params.page])

  return (
    <NavigationWrapper>
      <NavButton title="Show all" link={'all'} />
      <NavButton title="Crypto" link={'crypto'} />
      <NavButton title="Fiat" link={'fiat'} />
    </NavigationWrapper>
  )
})

const BalancesPage = observer(() => {
  return (
    <>
      <Filters />
      <Outlet />
    </>
  )
})

export default BalancesPage
