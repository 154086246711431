import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from 'src/models/Store'
import Button from 'src/ui/Button'
import Input from 'src/ui/Input/Input'
import { useEffect } from 'react'
import { EAlertTypes } from 'src/models/AlertsStore'
import classNames from 'classnames'
import styles from './NewPasswordAfterReset.module.scss'
import TextComponent from 'src/ui/TextComponent/TextComponent'

type NewPasswordAfterResetFormInputs = {
  new_password: string
  re_new_password: string
}

const NewPasswordAfterReset: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch
  } = useForm<NewPasswordAfterResetFormInputs>({
    defaultValues: { new_password: '', re_new_password: '' },
    criteriaMode: 'all'
  })

  const { userStore, alertsStore } = useStore()
  const navigate = useNavigate()
  const searchParams = useSearchParams()

  useEffect(() => {
    const resetCode = searchParams[0].get('code')
    if (resetCode === null) {
      alertsStore.addAlert({
        id: 'password-reset-failure',
        title: 'Reset password',
        content: 'Reset code not found',
        type: EAlertTypes.ERROR,
        timeout: 10000
      })
      navigate('/login')
    } else {
      // Fix later
      // const getCode = async (): Promise<void> => {
      //   const resetCodeValidation = await userStore.resetPasswordConfirmation(
      //     resetCode
      //   )
      //   if (!resetCodeValidation) {
      //     navigate('/login')
      //   }
      // }
      // getCode().catch(() => {})
    }
  }, [])

  const onSubmit: SubmitHandler<NewPasswordAfterResetFormInputs> = async ({
    new_password,
    re_new_password
  }) => {
    const response = await userStore.createNewPassword(
      new_password,
      re_new_password,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      searchParams[0].get('code')!
    )
    if (response) {
      navigate('/login')
    }
  }
  const currentPassword = watch('new_password')
  return (
    <div className={styles.NewPasswordAfterReset}>
      <h3>Change password</h3>
      <Input
        label="New password"
        type="password"
        {...register('new_password', {
          required: { message: 'Please enter a new password', value: true },
          minLength: {
            message: 'Passwords must be at least 8 characters long',
            value: 8
          },
          validate: {
            latinOnly: (value) =>
              /^[A-Za-z0-9!@#$%^&*()'"_+{}[\]:;<>,.?~\\/-]+$/.test(value) ||
              'Only latin characters allowed',
            specialCharacters: (value) =>
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#:;,.^()-]).+$/.test(
                value
              ) ||
              'At least one lowercase letter, uppercase letter, digit and special character required'
          }
        })}
        errors={errors.new_password}
      />
      <Input
        label="Confirm new password"
        type="password"
        {...register('re_new_password', {
          validate: (value) =>
            value === currentPassword || 'Passwords must match'
        })}
        errors={errors.re_new_password}
      />

      <TextComponent body2>Your password must contain</TextComponent>
      <ul
        style={{
          listStyle: 'none',
          padding: 0
        }}
      >
        <li
          className={classNames(styles.PasswordRequirements, {
            [styles.Complete]:
              isSubmitted &&
              !(errors.new_password?.types?.latinOnly as boolean),
            [styles.Error]: errors.new_password?.types?.latinOnly as boolean
          })}
        >
          <TextComponent body4>
            Letters of the Latin alphabet only
          </TextComponent>
        </li>
        <li
          className={classNames(styles.PasswordRequirements, {
            [styles.Complete]:
              isSubmitted &&
              !(errors.new_password?.types?.minLength as boolean),
            [styles.Error]: errors.new_password?.types?.minLength as boolean
          })}
        >
          <TextComponent body4>
            Passwords must be at least 8 characters long
          </TextComponent>
        </li>
        <li
          className={classNames(styles.PasswordRequirements, {
            [styles.Complete]:
              isSubmitted &&
              !(errors.new_password?.types?.specialCharacters as boolean),
            [styles.Error]: errors.new_password?.types
              ?.specialCharacters as boolean
          })}
        >
          <TextComponent body4>
            One capital letter, number or special character
          </TextComponent>
        </li>
      </ul>
      <Button title="Change password" onClick={handleSubmit(onSubmit)} />
    </div>
  )
}
export default NewPasswordAfterReset
