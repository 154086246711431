import classNames from 'classnames'
import styles from './ColoredStatusCircle.module.scss'

type ColoredStatusCircleProps = { status: 'success' | 'pending' | 'failed' }

const ColoredStatusCircle = ({ status }: ColoredStatusCircleProps) => {
  const classnames = classNames(
    styles.ColoredStatusCircle,
    styles[status.toLowerCase()]
  )

  return <span className={classnames} />
}
export default ColoredStatusCircle
