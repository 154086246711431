import { autorun, makeAutoObservable, runInAction } from 'mobx'
import type Store from '../Store'
import PaginationStore from '../PaginationStore'
import FiltersStore from '../FiltersStore'
import {
  type RiskCheckPageFilters,
  type RiskCheckAddress
} from './RiskCheckStore.types'
import { type BasePaginationRequest } from '../FetchModel'
import { get } from 'http'

class RiskCheckStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<RiskCheckStore, 'rootStore'>(this, { rootStore: false })

    this.filters = new FiltersStore<RiskCheckPageFilters>()
    this.pagination = new PaginationStore()
  }

  filters
  pagination: PaginationStore

  riskCheckHistory: { items: RiskCheckAddress[]; isLoading: boolean } = {
    items: [],
    isLoading: false
  }

  listener = () => {}

  subscribe() {
    this.listener = autorun(async () => {
      await this.getRiskCheckHistory()
    })
  }

  unsubscribe(): void {
    this.listener()
    this.filters.resetFilters()
  }

  async getRiskCheckHistory() {
    const response = await this.rootStore.api.get<
      {
        items: RiskCheckAddress[]
      } & BasePaginationRequest
    >('/jsapi/risk_check/merchant', {
      page: this.pagination.currentPage,
      page_size: this.pagination.itemsPerPage
    })
    if (response !== undefined) {
      runInAction(() => {
        this.riskCheckHistory.items = response.items
        this.pagination.init(response.page_size, response.total)
      })
    }
  }

  async riskCheckAddress(address: string) {
    const response = await this.rootStore.api.get<RiskCheckAddress>(
      '/jsapi/risk_check/address',
      { address }
    )
    if (response !== undefined) {
      void this.getRiskCheckHistory()
      return response
    }
    return response
  }
}

export default RiskCheckStore
