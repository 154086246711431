import type React from 'react'
import './TopUpFiatPage.scss'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import { colors } from 'src/assets/scss/themes'
import Payin from '../../assets/images/svg/Payin.svg'
import { useParams } from 'react-router-dom'
import AlertIcon from 'src/assets/images/svg/AlertIcon'
import { useEffect } from 'react'
import { useStore } from 'src/models/Store'
import { observer } from 'mobx-react'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import ComponentLoading from 'src/ui/Loaders/ComponentLoading/ComponentLoading'

const TopUpFiatPage: React.FC = observer(() => {
  const { currency } = useParams()
  const { fiatStore } = useStore()
  const { currentDepositCredentials } = fiatStore
  if (currency === undefined) {
    return <p>No currency provided</p>
  }

  useEffect(() => {
    void fiatStore.getDepositCredentials(currency)
    return () => {
      fiatStore.currentDepositCredentials = null
    }
  }, [currency])

  if (currentDepositCredentials == null) {
    return <ComponentLoading />
  }
  const { account_name, bank_name, bic, iban, reference_code } =
    currentDepositCredentials

  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`Deposit to your ${currency} account`}
    >
      <div className="TopUpFiatPage">
        <p style={{ textAlign: 'center' }}>
          <AlertIcon fill={colors.main} color={colors.bg} /> International money
          transfer in euros within the Single Euro Payments
        </p>
        <ol className="TopUpFiatPage-Requirements">
          <li>SEPA transfer</li>
          {/* <li>TODO REQUIREMENTS</li> */}
          {/* <li>123</li> */}
        </ol>
        <h3>Transfer funds to bank account below</h3>
        <div>
          <div className="WrapperFlexBetween">
            <TextComponent>Account name</TextComponent>
            <TextComponent>{account_name}</TextComponent>
          </div>
          <div className="WrapperFlexBetween">
            <TextComponent>Bank name</TextComponent>
            <TextComponent>{bank_name}</TextComponent>
          </div>
          <div className="WrapperFlexBetween">
            <TextComponent>IBAN</TextComponent>
            <TextComponent>{iban}</TextComponent>
          </div>
          <div className="WrapperFlexBetween">
            <TextComponent>BIC</TextComponent>
            <TextComponent>{bic}</TextComponent>
          </div>
          <div className="WrapperFlexBetween">
            <TextComponent>Currency</TextComponent>
            <TextComponent>{account_name}</TextComponent>
          </div>
          {reference_code != null && (
            <div className="WrapperFlexBetween">
              <TextComponent>Reference code</TextComponent>
              <TextComponent>{reference_code}</TextComponent>
            </div>
          )}
        </div>
      </div>
    </BaseSideBarComponent>
  )
})
export default TopUpFiatPage
