import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import NavButton from 'src/components/Navigation/NavButton/NavButton'
import NavigationWrapper from 'src/components/Navigation/NavigationWrapper/NavigationWrapper'

const FeesFilters = observer(() => {
  return (
    <NavigationWrapper>
      <NavButton link={'c2b'} title="C2B" />
      <NavButton link={'b2b'} title="B2B" />
    </NavigationWrapper>
  )
})

export default FeesFilters
