import { autorun, makeAutoObservable, when } from 'mobx'
import type Store from './Store'
import PaginationStore from './PaginationStore'
import FiltersStore from './FiltersStore'
import { type BasePaginationRequest } from './FetchModel'

export interface IReport {
  id: number
  type: string
  period: string
  date_created: string
  status: 'created' | 'pending' | 'ready' | 'error'
}

type TReportType = 'transactions' | 'balances'
type ReportsPageFilters = {
  type: TReportType
  page: number
  page_size: number
}

class ReportsStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<ReportsStore, 'rootStore'>(this, { rootStore: false })

    this.pagination = new PaginationStore()
    this.filters = new FiltersStore<ReportsPageFilters>({
      type: 'transactions'
    })

    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(async () => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getReports({
              ...this.filters.activeFilters,
              ...this.pagination.initialize()
            })
          }
        })
      }
    )
  }

  filters
  reports: IReport[] = []
  // Leave 10 for now since pagination does not have selecting row count
  pageSize = 10
  pagination: PaginationStore

  private setReports(reports: IReport[]): void {
    this.reports = reports
  }

  public async createReport(
    reportType: TReportType,
    dateStart: Date,
    dateEnd: Date | null
  ): Promise<typeof response> {
    const response = await this.rootStore.api.post<IReport>(
      'jsapi/reports',
      {
        type: reportType,
        data: {
          date_start: dateStart.toJSON().slice(0, 10),
          date_end: dateEnd?.toJSON().slice(0, 10)
        }
      },
      { content: 'Report created!' }
    )
    if (response !== undefined) {
      // Update reports when creating a new one
      void this.getReports({
        ...this.filters.activeFilters,
        page: this.pagination.currentPage,
        page_size: this.pageSize
      })
    }
    return response
  }

  public async getReports(params: ReportsPageFilters): Promise<void> {
    const response = await this.rootStore.api.get<
      { items: IReport[] } & BasePaginationRequest
    >('jsapi/reports', {
      ...params
    })
    if (response !== undefined) {
      this.pagination.init(response.page_size, response.total)
      this.setReports(response.items)
      if (response.items.some((r) => r.status === 'pending')) {
        setTimeout(() => {
          void this.getReports({
            ...this.filters.activeFilters,
            page: this.pagination.currentPage,
            page_size: this.pageSize
          })
        }, 5000)
      }
    }
  }

  public getReportDownloadable(reportId: number): void {
    const baseUrl = window.location.origin
    window.open(`${baseUrl}/jsapi/reports/${reportId}`)
  }

  async generateReport(reportId: string | number, reportType: TReportType) {
    await this.rootStore.api.get('jsapi/reports/' + reportId.toString())
    await this.getReports({
      page: this.pagination.currentPage,
      page_size: this.pageSize,
      type: reportType
    })
  }
}

export default ReportsStore
