import type React from 'react'
// import './PasswordResetSuccess.scss'
import Button from 'src/ui/Button'
import { useNavigate } from 'react-router-dom'

const PasswordResetSuccess: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="PasswordResetSuccess">
      <h3>Email sent</h3>
      <p>
        We have sent you an email. Follow it&apos;s instructions to reset your
        password.
      </p>
      <Button
        title="Login"
        onClick={() => {
          navigate('/login')
        }}
      />
    </div>
  )
}
export default PasswordResetSuccess
