import { autorun, makeAutoObservable, runInAction } from 'mobx'

class PaginationStore {
  currentPage = 1
  itemsPerPage = 10
  total = 0
  isInitiated = false

  constructor() {
    makeAutoObservable<PaginationStore>(this)
  }

  initialize() {
    return { page: this.currentPage, page_size: this.itemsPerPage }
  }

  init(pageSize: number, total: number): void {
    runInAction(() => {
      this.itemsPerPage = pageSize
      this.total = total
      this.isInitiated = true
    })
  }

  test(currentPage: number, page_size: number) {
    return {
      page: currentPage,
      page_size
    }
  }

  get pagesLength(): number {
    return Math.ceil(this.total / this.itemsPerPage)
  }

  get activeBack(): boolean {
    return this.currentPage > 0
  }

  get activeNext(): boolean {
    return this.currentPage < this.total
  }

  setPage(page: number): void {
    autorun(() => {
      this.currentPage = page
    })
  }

  next(): void {
    this.setPage(this.currentPage + 1)
  }

  /**
   * @deprecated use 'resetWithParams' instead, will be removed
   */
  reset(): void {
    this.currentPage = 1
    this.itemsPerPage = 10
    this.total = 0
  }

  resetWithParams(itemsPerPage: number): void {
    this.currentPage = 1
    this.itemsPerPage = itemsPerPage
  }

  resetPage() {
    this.currentPage = 1
  }

  back(): void {
    this.setPage(this.currentPage - 1)
  }
}

export default PaginationStore
