import { type FC } from 'react'
import { NavLink } from 'react-router-dom'
import useSideBarModal from '../../hooks/useSideBarModal'

export type TMenuButtonProps = {
  title: string
  link: string
  active?: boolean
  end?: boolean
}

/**
 * @deprecated Use `NavButton` instead
 */
const MenuButton: FC<TMenuButtonProps> = ({
  title,
  link,
  active = false,
  end = false
}) => {
  const { closeModal } = useSideBarModal()
  return (
    <NavLink
      to={link}
      onClick={closeModal}
      className={({ isActive }) =>
        isActive ? 'menu-button active' : 'menu-button'
      }
      end={end}
    >
      {title}
    </NavLink>
  )
}

export default MenuButton
