import type React from 'react'
import './ExchangeModal.scss'
import ExchangeModalQuota from './ExchangeModalQuota'
import Button from 'src/ui/Button'
import useSideBarModal from 'src/hooks/useSideBarModal'
import { type ExchangeQuota } from 'src/models/ExchangeStore'

type ExchangeModalCompleteExchangeProps = {
  exchangeFromCoin: string
  exchangeToCoin: string
  quota: ExchangeQuota
}

const ExchangeModalCompleteExchange: React.FC<
  ExchangeModalCompleteExchangeProps
> = ({ exchangeFromCoin, exchangeToCoin, quota }) => {
  const { closeModal } = useSideBarModal()
  return (
    <div className="ExchangeModal">
      <h2>Exchange request is completed</h2>
      <div className="ExchangeModal-ExchangeComplete">
        <img src={`/assets/images/ps/${exchangeFromCoin.toLowerCase()}.svg`} />
        <div>
          {quota.from_amount} {quota.from_currency}
        </div>
      </div>
      Converted to:
      <div className="ExchangeModal-ExchangeComplete">
        <img src={`/assets/images/ps/${exchangeToCoin.toLowerCase()}.svg`} />
        <div>
          {quota.to_amount} {quota.to_currency}
        </div>
      </div>
      <ExchangeModalQuota quota={quota} />
      <Button title="Done" wide onClick={closeModal} />
    </div>
  )
}
export default ExchangeModalCompleteExchange
