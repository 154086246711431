import styles from './TableSortingArrow.module.scss'
import { type SortDirection } from '@tanstack/react-table'
import Arrow from 'src/assets/images/svg/Arrow.svg'
import { type ComponentPropsWithoutRef } from 'react'
import classNames from 'classnames'
import { SortingIcon } from 'src/assets/svg'
type TableSortingArrowProps = {
  sortingState: false | SortDirection
} & Pick<ComponentPropsWithoutRef<'button'>, 'onClick'>

const TableSortingArrow = ({
  sortingState,
  onClick
}: TableSortingArrowProps) => {
  const classnames = classNames(styles.TableSortingArrow, {
    [styles.Active]: sortingState !== false,
    [styles.Ascending]: sortingState === 'asc',
    [styles.Descending]: sortingState === 'desc'
  })
  return (
    <button className={classnames} onClick={onClick}>
      <SortingIcon />
    </button>
  )
}

export default TableSortingArrow
