import Header from '../../components/Header/Header'
import { Col, Row } from 'react-bootstrap'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Children, type ReactElement, useEffect, useRef } from 'react'
import type React from 'react'
import { useStore } from '../../models/Store'
import Preloader from '../Preloader'
import { CSSTransition } from 'react-transition-group'
import { toJS } from 'mobx'
import './Layout.scss'
/**
 *
 * This modal is used with react Router
 *
 * Use this when modal has to be displayed through a route
 */
const SideBarModal = observer(
  ({ children }: { children?: React.ReactNode }): JSX.Element => {
    // const [isModalOpen, setIsModalOpen] = useState(true)
    const { utilityStore } = useStore()
    const navigate = useNavigate()
    const nodeRef = useRef(null)

    useEffect(() => {
      utilityStore.openSideBarModal()
      console.log(toJS(utilityStore.sideBarModalOpen))
    }, [])

    const handleClose = (): void => {
      navigate('..')
    }

    const handleContentClick = (e: React.MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation()
    }
    return (
      <CSSTransition
        classNames="slide-left"
        nodeRef={nodeRef}
        in={utilityStore.sideBarModalOpen}
        timeout={300}
        appear
        mountOnEnter
        unmountOnExit
        onExited={() => {
          handleClose()
        }}
      >
        <div
          ref={nodeRef}
          className={'SideBarModal'}
          onClick={(e) => {
            utilityStore.closeSideBarModal()
          }}
        >
          <div className={'SideBarModal-Overlay'} />
          <div
            className={'SideBarModal-Content'}
            onClick={(e) => {
              handleContentClick(e)
            }}
          >
            {/* @ts-expect-error 123 */}
            {((<Outlet />) as ReactElement | null | undefined) ?? children}
          </div>
        </div>
      </CSSTransition>
    )
  }
)

const ProtectedPage = observer(() => {
  const { authStore, initialStore } = useStore()
  const location = useLocation()
  if (!authStore.isFetched)
    return (
      <Row
        style={{ height: '100vh' }}
        className={'justify-content-center align-content-center'}
      >
        <Preloader />
      </Row>
    )
  if (!authStore.isAuth && authStore.isFetched) {
    return <Navigate to="/login" state={{ from: location }} />
  }
  if (!initialStore.isFetched || initialStore.isLoading) {
    return (
      <Row
        style={{ height: '100vh' }}
        className={'justify-content-center align-content-center'}
      >
        <Preloader />
      </Row>
    )
  }
  return (
    <main className="Layout">
      <Header />
      <Row className={`outlet-row ${authStore.isFetched.toString()}`}>
        <Outlet />
      </Row>
    </main>
  )
})

export { ProtectedPage, SideBarModal }
