import type Store from '../Store'
import { action, autorun, makeAutoObservable, runInAction, when } from 'mobx'
import FiltersStore from '../FiltersStore'
import { type CommissionCurrencyType } from './CommissionsStore.types'

type TFeeCommisions = {
  percent_value: string
  min_amount: string
  absolute_amount: string
  min_amount_currency: string
  network_id: string
}

type Fees = Record<string, Record<CommissionCurrencyType, TFeeCommisions[]>>

type CommissionsPageFilters = {
  commission_type: 'b2b' | 'c2b'
}

class CommissionsStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<CommissionsStore, 'rootStore'>(this, {
      rootStore: false,
      getFees: action
    })

    this.filters = new FiltersStore<CommissionsPageFilters>()
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(() => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getFees({ commission_type: 'b2b' })
            void this.getFees({ commission_type: 'c2b' })
          }
        })
      }
    )
  }

  filters

  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false
  b2b: Fees = {}
  c2b: Fees = {}

  async getFees(params: CommissionsPageFilters): Promise<void> {
    this.isLoading = true
    const response = await this.rootStore.api.get<Fees>(
      '/jsapi/commissions',
      params
    )

    if (response !== undefined) {
      runInAction(() => {
        if (params.commission_type === 'b2b') {
          this.b2b = response
        } else {
          this.c2b = response
        }
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }
}

export default CommissionsStore
