import { observer } from 'mobx-react'
import styles from './TableFilterIcon.module.scss'
import FilterIcon from 'src/assets/svg/FilterIcon.svg'
import { type ComponentPropsWithoutRef } from 'react'
import classNames from 'classnames'

type TableSortingArrowProps = {
  filterActive: boolean
} & Pick<ComponentPropsWithoutRef<'button'>, 'onClick'>

const TableFilterIcon = observer(
  ({ filterActive, onClick }: TableSortingArrowProps) => {
    const classnames = classNames(styles.TableFilterIcon, {
      [styles.Active]: filterActive
    })
    return (
      <button className={classnames} onClick={onClick}>
        <FilterIcon />
      </button>
    )
  }
)
export default TableFilterIcon
