import { autorun, makeAutoObservable, runInAction } from 'mobx'
import type Store from '../Store'
import { type NotificationSetting } from './NotificationsStore.types'

class NotificationsStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<NotificationsStore, 'rootStore'>(this, {
      rootStore: false
    })
  }

  notificationSettings: NotificationSetting[] = []

  listener = () => {}

  subscribe(): void {
    this.listener = autorun(() => {
      void this.getNotificationsSettings()
    })
  }

  unsubscribe(): void {
    this.listener()
  }

  async getNotificationsSettings() {
    const response = await this.rootStore.api.get<NotificationSetting[]>(
      'jsapi/notifications-settings'
    )

    if (response !== undefined) {
      runInAction(() => {
        this.notificationSettings = response
      })
    }
  }
}

export default NotificationsStore
