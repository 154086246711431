/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
// This will be changed later for an updated design
import { useState } from 'react'
import { observer } from 'mobx-react'
import QRCode from 'react-qr-code'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Button, { EButtonType } from '../../ui/Button'
import colors from '../../assets/scss/themes/colors.module.scss'
import Payin from '../../assets/images/svg/Payin.svg'
import Info from '../../assets/images/svg/informationlineforheading.svg'
import BaseSideBarComponent from '../../components/BaseSideBarComponent/BaseSideBarComponent'
import styles from './payin.module.scss'
import CopyTextComponent from 'src/ui/CopyTextComponent/CopyTextComponent'
import { useStore } from 'src/models/Store'
import Select from 'src/components/Select/Select'
import { type PaymentAttribute } from 'src/models/BalancesStore'

const PayinPage = observer(() => {
  const navigate = useNavigate()
  const { balanceId } = useParams()
  const { balancesStore, initialStore } = useStore()
  const [selectedNetwork, setSelectedNetwork] = useState<{
    label: string
    value: number
  } | null>(null)
  const [paymentAttribute, setPaymentAttribute] =
    useState<PaymentAttribute | null>(null)

  if (!balanceId) {
    return <p>No network provided</p>
  }
  const balanceItem = balancesStore.items.find(
    (item) => item.id === parseInt(balanceId)
  )
  const availableNetworks = balanceItem?.operations_availability.filter(
    (item) => item.type === 'top_up_deposit'
  )

  const filteredNetworks = initialStore.networks.items.filter((network) =>
    availableNetworks?.some(
      (availableNetwork) => availableNetwork.network_id === network.id
    )
  )

  const selectOptions = filteredNetworks.map((network) => {
    return { label: network.name, value: network.id }
  })
  const handleNetworkSelect = async (newValue: {
    label: string
    value: number
  }) => {
    const response = await balancesStore.getPaymentAttributes(newValue.value)
    setSelectedNetwork(newValue)
    if (response) {
      setPaymentAttribute(response[newValue.value])
    }
  }
  return (
    <BaseSideBarComponent
      headerIcon={<Payin fill={colors.main} stroke={colors.main} />}
      headerTitle={`${balanceItem?.currency ?? ''} Send (Top up)`}
    >
      <div className={styles.payinPage}>
        <div
          className={styles.info}
          style={{ fontSize: '.8em', alignItems: 'center' }}
        >
          <Info fill={colors.error} color={colors.white} />
          <p>
            Please check carefully your network selection. If the top up is
            submitted within the wrong network, the funds will be lost.
          </p>
        </div>
        <p>Wallet network</p>
        <Select
          transparent
          options={selectOptions}
          className={styles.Select}
          placeholder={'Select network'}
          onChange={(newValue) => {
            // @ts-expect-error for now
            handleNetworkSelect(newValue)
          }}
        />
        {selectedNetwork !== null && paymentAttribute !== null && (
          <>
            <div className={styles.qrWrapper} style={{ marginTop: 20 }}>
              <QRCode
                value={paymentAttribute.address}
                style={{ width: 180, height: 180 }}
              />
            </div>
            <Row>
              <Col>
                <p className={'text-center'} style={{ fontSize: '.7em' }}>
                  You can copy deposit address below
                </p>
              </Col>
            </Row>
            <CopyTextComponent text={paymentAttribute.address} />
            {paymentAttribute?.memo && (
              <>
                <p className={styles.memo}>Memo</p>
                <CopyTextComponent text={paymentAttribute.memo} />
              </>
            )}
            <Button
              classes={styles.doneBtn}
              buttonType={EButtonType.PRIMARY}
              onClick={() => {
                navigate('../..')
              }}
              title={'Done'}
              style={{ width: '100%' }}
            />
          </>
        )}
      </div>
    </BaseSideBarComponent>
  )
})

export default PayinPage
