import { observer } from 'mobx-react'
import { useState, type ChangeEvent } from 'react'
import styles from './search.module.scss'
import type FiltersStore from 'src/models/FiltersStore'
import './Search.scss'
import { useDebouncedCallback } from 'use-debounce'
import Button, { EButtonType } from '../Button'
import ClearIcon from '../../assets/images/svg/clear.svg'

export type TSearchFilter = {
  title: string
  onDebouncedChange: (value: string) => void
}
// TODO: Move to new filter and change type name
type TTSearchFilter<T extends Record<string, unknown>> = {
  title: string
  /**
   * Specific filter store
   */
  filtersStore: FiltersStore<T>
  /**
   * Filter name that is used to send to BE, found in config of all FilterStore's
   */
  filterNameFromFilterConfig: keyof T

  onChange?: () => void
}

const Search = observer(({ title, onDebouncedChange }: TSearchFilter) => {
  const [input, setInput] = useState('')

  const debouncedInputChange = useDebouncedCallback((e) => {
    onDebouncedChange(e)
  }, 300)
  return (
    <>
      <div className="btn-group">
        <div
          style={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor: '#2E2E2E',
            padding: '0 10px',
            fontSize: '0.8em',
            color: '#F3FF00',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {title}
        </div>
        <input
          name={'foreignId'}
          placeholder={'Search...'}
          className={styles.searchInput}
          value={input}
          onChange={(e) => {
            setInput(e.target.value)
            debouncedInputChange(e.target.value)
          }}
        />
        {input !== '' && (
          <Button
            icon={<ClearIcon style={{ marginRight: 3 }} />}
            title={'Clear'}
            style={{ minHeight: 'initial' }}
            onClick={() => {
              setInput('')
              debouncedInputChange('')
            }}
            buttonType={EButtonType.LINK}
          />
        )}
      </div>
    </>
  )
})

export const SearchFilter = observer(
  <T extends Record<string, unknown>>({
    title,
    filtersStore,
    filterNameFromFilterConfig,
    onChange
  }: TTSearchFilter<T>) => {
    return (
      <>
        <div className="btn-group">
          <div
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: '#2E2E2E',
              padding: '0 10px',
              fontSize: '0.8em',
              color: '#F3FF00',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
            className="SearchFilter"
          >
            {title}
          </div>
          <input
            name={'foreignId'}
            placeholder={'Search ...'}
            className={styles.searchInput}
            // @ts-expect-error FIX LATER
            value={
              filtersStore.getActiveFilterState(filterNameFromFilterConfig) ??
              ''
            }
            onChange={(e) => {
              if (onChange !== undefined) {
                onChange()
              }
              filtersStore.setFilter(filterNameFromFilterConfig, e.target.value)
            }}
          />
        </div>
      </>
    )
  }
)

export default Search
