import { observer } from 'mobx-react'
import { Dropdown, WrapperFlex } from 'src/ui'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import type FiltersStore from 'src/models/FiltersStore'
import TableFilterIcon from './TableFilterIcon/TableFilterIcon'
import styles from './TableDropdownSelectFilter.module.scss'

type TableDropdownSelectFilterProps<T extends Record<string, unknown>> = {
  filter: keyof T
  filtersStore: FiltersStore<T>
  items: Array<{ name: string; value: string | boolean; imagePath?: string }>
  resetFilterTitle: string
}

export const TableDropdownSelectFilter = observer(
  <T extends Record<string, unknown>>({
    filtersStore,
    filter,
    items,
    resetFilterTitle
  }: TableDropdownSelectFilterProps<T>) => {
    return (
      <Dropdown
        dropdownToggle={
          <TableFilterIcon
            filterActive={
              filtersStore.getActiveFilterState(filter) !== undefined
            }
          />
        }
      >
        <li
          onClick={() => {
            filtersStore.setFilter(filter, null)
          }}
          className={
            filtersStore.getActiveFilterState(filter) === undefined
              ? styles.ActiveItem
              : undefined
          }
        >
          <TextComponent>{resetFilterTitle}</TextComponent>
        </li>
        {items.map(({ name, value, imagePath }) => (
          <li
            key={name}
            onClick={() => {
              filtersStore.setFilter(filter, value)
            }}
            className={
              filtersStore.getActiveFilterState(filter) === value
                ? styles.ActiveItem
                : undefined
            }
          >
            <WrapperFlex gap={10}>
              {imagePath !== undefined && (
                <img src={imagePath + '/' + name + '.svg'} />
              )}
              <TextComponent>{name}</TextComponent>
            </WrapperFlex>
          </li>
        ))}
      </Dropdown>
    )
  }
)
