import { type TableStyles } from 'react-data-table-component'

export const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: '#000000'
    }
  },
  headRow: {
    style: {
      backgroundColor: '#2E2E2E',
      color: 'white'
    }
  },
  rows: {
    style: {
      color: 'white',
      backgroundColor: '#000000',
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #474747'
      }
    },
    highlightOnHoverStyle: {
      backgroundColor: 'var(--color-primary600)',
      outline: 'none',
      borderBottom: '1px solid transparent',
      color: 'white'
    }
  },
  pagination: {
    style: {
      color: 'white',
      backgroundColor: '#000000'
    },
    pageButtonsStyle: {
      fill: 'white'
    }
  },
  noData: {
    style: {
      backgroundColor: '#000000'
    }
  },
  tableWrapper: {
    style: {
      marginBottom: '30px',
      minHeight: '200px'
    }
  },
  responsiveWrapper: {
    style: { overflowY: 'visible' }
  }
}
