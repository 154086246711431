import { type TableColumn } from 'react-data-table-component'
import { type FiatBankAccountsDetails } from 'src/models/FiatStore'
import SEPA from '../../../assets/images/svg/SEPA.svg'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import { useStore } from 'src/models/Store'
import { Dropdown } from 'react-bootstrap'
import DotsMenuDropdown from 'src/components/DotsMenuDropdown/DotsMenuDropdown'
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Outlet, useNavigate } from 'react-router-dom'
import './FiatAddressesPage.scss'
import Button from 'src/ui/Button'
import { SearchFilter } from 'src/ui/Filters/Search'
import ClearFiltersButton from 'src/ui/Filters/ClearFiltersButton/ClearFiltersButton'

const columns: Array<TableColumn<FiatBankAccountsDetails>> = [
  {
    name: 'Action',
    cell: (row) => actionMenuButton(row),
    button: true,
    minWidth: '50px',
    id: 1
  },
  {
    name: 'Account name',
    selector: (row) => row.account_name,
    center: true,
    maxWidth: '150px',
    style: {
      backgroundColor: '#2E2E2E',
      borderRadius: 10,
      height: 25,
      alignSelf: 'center'
    },
    id: 2
  },
  {
    name: 'Bank transfer method',
    cell: () => (
      <div>
        <SEPA /> SEPA
      </div>
    ),
    center: true,
    id: 3
  },
  { name: 'Bank name', selector: (row) => row.bank_name, id: 4 },
  {
    name: 'IBAN',
    selector: (row) => row.iban,
    id: 5
  },
  {
    name: 'BIC',
    selector: (row) => row.bic,
    id: 6
  }
]

const actionMenuButton = (address: FiatBankAccountsDetails): JSX.Element => {
  const navigate = useNavigate()

  return (
    <DotsMenuDropdown>
      <Dropdown.Item
        onClick={() => {
          navigate(`manage/edit/${address.id}`)
        }}
      >
        Edit Address
      </Dropdown.Item>
      <Dropdown.Item
        className="ApiKeysPage-DropDownDeleteButton"
        onClick={() => {
          navigate(`manage/delete/${address.id}`)
        }}
      >
        Delete Address
      </Dropdown.Item>
    </DotsMenuDropdown>
  )
}

const FiatAddressesFilters = observer((): JSX.Element => {
  const { fiatStore } = useStore()

  return (
    <>
      <SearchFilter
        title="Account name"
        filtersStore={fiatStore.filters}
        filterNameFromFilterConfig={'account_name'}
      />
      <SearchFilter
        title="Bank name"
        filtersStore={fiatStore.filters}
        filterNameFromFilterConfig={'bank_name'}
      />
      <ClearFiltersButton filtersStore={fiatStore.filters} />
    </>
  )
})

const FiatAddressesPage = observer(() => {
  const { fiatStore } = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    fiatStore.subscribe()
    return () => {
      fiatStore.unsubscribe()
    }
  }, [])

  return (
    <div className="AddressesPage">
      <div className="AddressesPage-Actions">
        <div className="AddressesPage-Filters">
          <FiatAddressesFilters />
        </div>
        <Button
          title="Add account"
          onClick={() => {
            navigate('manage/add')
          }}
        />
      </div>
      <BaseDataTable
        columns={columns}
        data={fiatStore.savedWithdrawAccounts}
        paginationLogic={fiatStore.pagination}
      />
      <Outlet />
    </div>
  )
})
export default FiatAddressesPage
