import { makeAutoObservable, runInAction } from 'mobx'

class FiltersStore<PossibleFilters extends Record<string, unknown>> {
  constructor(initialFilters?: Partial<PossibleFilters>) {
    makeAutoObservable<FiltersStore<PossibleFilters>>(this)

    if (initialFilters !== undefined) {
      this.setInitialFilters(initialFilters)
    }
  }

  activeFilters: PossibleFilters = {} as PossibleFilters

  private setInitialFilters(initialFilters: Partial<PossibleFilters>) {
    this.activeFilters = initialFilters as PossibleFilters
  }

  setFilter(field: keyof PossibleFilters, value: unknown) {
    runInAction(() => {
      if (value === null || value === '') {
        this.activeFilters[field] = undefined as any
      } else {
        this.activeFilters[field] =
          value as PossibleFilters[keyof PossibleFilters]
      }
    })
  }

  getActiveFilterState(field: keyof PossibleFilters) {
    return this.activeFilters[field]
  }

  getIsFilterActive(field: keyof PossibleFilters) {
    return this.activeFilters[field] !== undefined
  }

  resetFilters() {
    this.activeFilters = {} as PossibleFilters
  }

  public get activeFilterCount(): number {
    return Object.keys(this.activeFilters).length
  }
}

export type TFieldValues = {
  field: string
  value: string | boolean
}

export default FiltersStore
