import { makeAutoObservable, runInAction } from 'mobx'
import type Store from './Store'
import { EAlertTypes } from './AlertsStore'

export type ExchangeQuota = {
  quote_id: string
  expire_time: number
  expire_timestamp: number
  from_currency: string
  from_amount: string
  request_amount: string
  request_currency: string
  to_currency: string
  to_amount: string
  exchange_rate: string
  reverse_exchange_rate: string
  fee_amount: string
  fee_currency: string
  merchant_id: number
  user_id: string | null
}

class ExchangeStore {
  constructor(private readonly rootStore: Store) {
    // private readonly rootStore: Store
    // this.rootStore = rootStore
    makeAutoObservable(this)
  }

  public isGeneratingQuota = false

  async createQuota(quoteParams: {
    from_currency: string
    to_currency: string
    request_amount: string
    request_currency: string
  }): Promise<ExchangeQuota | undefined> {
    runInAction(() => (this.isGeneratingQuota = true))
    const response = await this.rootStore.api.post<ExchangeQuota>(
      '/jsapi/exchange/createquota',
      quoteParams
    )
    runInAction(() => (this.isGeneratingQuota = false))
    return response
  }

  /**
   * Provides user with the available amount of a specific coin that could be spent
   *
   * Backend calculates fees etc
   */
  async getNetworkExchangeLimits({
    currency,
    network_id
  }: {
    currency: string
    network_id: number
  }): Promise<
    | {
        currency: string
        limit: string
      }
    | undefined
  > {
    return await this.rootStore.api.post('/jsapi/exchange/limit', {
      currency,
      network_id
    })
  }

  async createExchange(
    quote_id: string
  ): Promise<{ success: boolean } | undefined> {
    const response = await this.rootStore.api.post<{ success: boolean }>(
      '/jsapi/exchange/createexchange',
      {
        quote_id
      }
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'exchange-success',
        title: 'Success!',
        content: 'Exchange request is completed.',
        type: EAlertTypes.SUCCESS,
        timeout: 10000
      })
      void this.rootStore.balancesStore.getBalances({
        ...this.rootStore.balancesStore.filters.activeFilters
      })
    }
    return response
  }
}

export default ExchangeStore
