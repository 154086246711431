import { observer } from 'mobx-react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  type RowData,
  flexRender
} from '@tanstack/react-table'

import TableSortingArrow from 'src/pages/TransactionsPage/TransactionsList/TableSortingArrow/TableSortingArrow'
import styles from './Table.module.scss'
type TableProps = {
  data: RowData[]
  columns: any
}

export const Table = observer(({ data, columns }: TableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <table className={styles.Table}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  <div className={styles.Header}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort() && (
                      <TableSortingArrow
                        onClick={header.column.getToggleSortingHandler()}
                        sortingState={header.column.getIsSorted()}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          )
        })}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
})
