import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import type {
  TransactionTypes,
  TTransactionListItem
} from 'src/models/TransactionStore'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import styles from './TransactionsList.module.scss'
import TextComponent from 'src/ui/TextComponent/TextComponent'
import { formatDate } from 'src/utils/helpers/formatDate'
import { useMemo } from 'react'
import TableSortingArrow from './TableSortingArrow/TableSortingArrow'
import { WrapperFlex } from 'src/ui'
import { TableDropdownSelectFilter } from 'src/ui/Filters/TableFilter/TableDropdownSelectFilter'
import { NetworkIcon } from 'src/ui/NetworkIcon/NetworkIcon'
import { DropdownSearch } from 'src/ui/Dropdowns/DropdownSearch/DropdownSearch'
import TableFilterIcon from './TableFilterIcon/TableFilterIcon'
import ColoredStatusCircle from 'src/ui/ColoredStatusCircle/ColoredStatusCircle'
import PaginationComponent from 'src/ui/Pagination/Pagination'
import { Table } from 'src/components/Table/Table'
import MagicSearch from 'src/ui/MagicSearch/MagicSearch'
import { toJS } from 'mobx'

const TransactionsList = observer(() => {
  const { transactionStore, initialStore } = useStore()
  const navigate = useNavigate()

  const handleNavigate = (item: TTransactionListItem) => {
    navigate(`info/${item.id}`)
  }
  const transactionTypes: Array<{ value: TransactionTypes; name: string }> = [
    {
      value: 'customer_deposit',
      name: 'Deposit'
    },
    {
      value: 'customer_deposit_with_exchange',
      name: 'Deposit With Exchange'
    },
    {
      value: 'top_up_deposit',
      name: 'Top up'
    },
    {
      value: 'customer_withdraw',
      name: 'Withdraw'
    },
    {
      value: 'customer_withdraw_with_exchange',
      name: 'Payout With Exchange'
    },
    {
      value: 'exchange_withdraw',
      name: 'Exchange'
    },
    {
      value: 'settlement_withdraw',
      name: 'Settlement'
    }
  ]
  const columnHelper = createColumnHelper<TTransactionListItem>()
  const defaultColumns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => <TextComponent body4>ID</TextComponent>,
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>,
        enableSorting: false
      }),
      columnHelper.accessor('created', {
        header: () => (
          <TextComponent body4 bold>
            Created
          </TextComponent>
        ),
        cell: (cell) => (
          <TextComponent body2>
            {formatDate(new Date(cell.getValue()))}
          </TextComponent>
        )
      }),
      columnHelper.accessor('amount', {
        header: () => (
          <TextComponent body4 bold>
            Amount
          </TextComponent>
        ),
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>
      }),
      columnHelper.accessor('network_fee', {
        header: () => (
          <TextComponent body4 bold>
            Network fee
          </TextComponent>
        ),
        cell: (cell) => (
          <TextComponent body2>{cell.getValue() ?? 'N/A'}</TextComponent>
        )
      }),
      columnHelper.accessor('type', {
        header: () => (
          <WrapperFlex gap={10}>
            <WrapperFlex gap={5}>
              <TextComponent body4 bold>
                Type:
              </TextComponent>
              <TextComponent body4 light capitalize>
                {transactionTypes.find(
                  (type) =>
                    type.value ===
                    transactionStore.filters.getActiveFilterState(
                      'transaction_type'
                    )
                )?.name ?? 'All transaction types'}
              </TextComponent>
            </WrapperFlex>
            <TableDropdownSelectFilter
              filtersStore={transactionStore.filters}
              resetFilterTitle="All Transaction Types"
              filter="transaction_type"
              items={transactionTypes}
            />
          </WrapperFlex>
        ),
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>,
        enableSorting: false
      }),
      columnHelper.accessor('status', {
        header: () => (
          <WrapperFlex gap={10}>
            <WrapperFlex gap={5}>
              <TextComponent body4 bold>
                Status:
              </TextComponent>
              <TextComponent body4 light capitalize>
                {transactionStore.filters.getActiveFilterState('status') ??
                  'All statuses'}
              </TextComponent>
            </WrapperFlex>
            <TableDropdownSelectFilter
              filtersStore={transactionStore.filters}
              resetFilterTitle="All statuses"
              filter="status"
              items={[
                { name: 'Success', value: 'success' },
                { name: 'Pending', value: 'pending' },
                { name: 'Failed', value: 'failed' }
              ]}
            />
          </WrapperFlex>
        ),
        cell: (cell) => (
          <WrapperFlex gap={5}>
            <ColoredStatusCircle
              status={cell.getValue() as 'success' | 'pending' | 'failed'}
            />
            <TextComponent capitalize body2>
              {cell.getValue()}
            </TextComponent>
          </WrapperFlex>
        ),
        enableSorting: false
      }),
      columnHelper.accessor('real_amount', {
        header: () => (
          <TextComponent body4 bold>
            Real amount
          </TextComponent>
        ),
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>
      }),
      columnHelper.accessor('amount_with_commission', {
        header: () => (
          <TextComponent body4 bold>
            Amount with commission
          </TextComponent>
        ),
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>
      }),
      columnHelper.accessor('txid', {
        header: () => (
          <WrapperFlex gap={10}>
            <TextComponent body4 bold>
              Transaction hash
            </TextComponent>
          </WrapperFlex>
        ),
        cell: (cell) => (
          <div
            className={styles.TransactionId}
            onClick={() => {
              handleNavigate(cell.row.original)
            }}
          >
            <TextComponent body2>{cell.getValue()}</TextComponent>
          </div>
        ),
        enableSorting: false
      }),
      columnHelper.accessor('coin', {
        header: () => (
          <WrapperFlex gap={10}>
            <WrapperFlex gap={5}>
              <TextComponent body4 bold>
                Coin:
              </TextComponent>
              <TextComponent body4 light capitalize>
                {initialStore.currency.find(
                  (coin) =>
                    coin.name ===
                    transactionStore.filters.getActiveFilterState('currency')
                )?.name ?? 'All coins'}
              </TextComponent>
            </WrapperFlex>
            <TableDropdownSelectFilter
              filtersStore={transactionStore.filters}
              resetFilterTitle="All coins"
              filter="currency"
              items={initialStore.currency.map((coin) => {
                return {
                  name: coin.name,
                  value: coin.name,
                  imagePath: '/assets/coins'
                }
              })}
            />
          </WrapperFlex>
        ),
        cell: (cell) => <TextComponent body2>{cell.getValue()}</TextComponent>,
        enableSorting: false
      }),
      columnHelper.accessor('network', {
        header: () => (
          <WrapperFlex gap={10}>
            <WrapperFlex gap={5}>
              <TextComponent body4 bold>
                Network:
              </TextComponent>
              <TextComponent body4 light capitalize>
                {initialStore.networks.items.find(
                  (item) =>
                    item.id.toString() ===
                    transactionStore.filters.getActiveFilterState('network')
                )?.name ?? 'All Networks'}
              </TextComponent>
            </WrapperFlex>
            <TableDropdownSelectFilter
              filtersStore={transactionStore.filters}
              resetFilterTitle="All Networks"
              filter="network"
              items={initialStore.networks.items.map((networkItem) => ({
                name: networkItem.name,
                value: networkItem.id.toString(),
                imagePath: '/assets/networks'
              }))}
            />
          </WrapperFlex>
        ),
        cell: (cell) => (
          <TextComponent capitalize body2>
            <NetworkIcon network={cell.getValue()} /> {cell.getValue()}
          </TextComponent>
        ),
        enableSorting: false
      }),
      columnHelper.accessor('foreign_id', {
        header: () => (
          <TextComponent body4 bold>
            Foreign ID
          </TextComponent>
        ),
        cell: (cell) => (
          <TextComponent body2>{cell.getValue() ?? 'N/A'}</TextComponent>
        )
      })
    ],
    []
  )

  return (
    <div className={styles.TransactionList}>
      <MagicSearch
        placeholder="Search by ID/Foreign ID/Transaction ID/Coin"
        onDebouncedChange={(e) => {
          transactionStore.pagination.resetPage()
          transactionStore.filters.setFilter('magic_search', e)
        }}
        active={transactionStore.filters.getIsFilterActive('magic_search')}
      />
      <div className={styles.Table}>
        <Table columns={defaultColumns} data={transactionStore.list} />
      </div>
      <PaginationComponent pagination={transactionStore.pagination} />
      <Outlet />
    </div>
  )
})
export default TransactionsList
