import './ReportsPage.scss'
import { Outlet } from 'react-router-dom'
import NavButton from 'src/components/Navigation/NavButton/NavButton'
import NavigationWrapper from 'src/components/Navigation/NavigationWrapper/NavigationWrapper'

const ReportsPage: React.FC = () => {
  return (
    <>
      <div className="ReportsPage">
        <NavigationWrapper>
          <NavButton end link="/reports" title="Transaction reports" />
          <NavButton link="balance-reports" title="Balance reports" />
        </NavigationWrapper>
      </div>
      <Outlet />
    </>
  )
}
export default ReportsPage
