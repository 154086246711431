import './ExchangeModal.scss'
import { type ExchangeQuota } from 'src/models/ExchangeStore'

type ExchangeModalQuotaProps = {
  quota: ExchangeQuota
}

const ExchangeModalQuota = ({ quota }: ExchangeModalQuotaProps) => {
  return (
    <div className="ExchangeModal-Rates">
      <div>
        <p>
          You exchange
          <span>
            {quota.from_amount} {quota.from_currency}
          </span>
        </p>
        <p>
          Fee
          <span>
            {quota.fee_amount} {quota.fee_currency}
          </span>
        </p>
        <p>
          Amount to exchange
          <span>
            {quota.from_amount} {quota.from_currency}
          </span>
        </p>
        <p>
          Exchange rate
          <span>{quota.exchange_rate}</span>
        </p>
        <p>
          You are getting
          <span>
            {quota.to_amount} {quota.to_currency}
          </span>
        </p>
      </div>
    </div>
  )
}
export default ExchangeModalQuota
