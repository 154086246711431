import { observer } from 'mobx-react'
import styles from './MagicSearch.module.scss'
import { SearchIcon } from 'src/assets/svg'
import { useState, type ComponentPropsWithoutRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import classNames from 'classnames'
import CloseIcon from 'src/assets/images/svg/CloseIcon'

type MagicSearchProps = {
  onDebouncedChange: (value: string) => void
  active: boolean
} & ComponentPropsWithoutRef<'input'>

const MagicSearch = observer(
  ({ onDebouncedChange, active, ...props }: MagicSearchProps) => {
    const [input, setInput] = useState('')

    const debouncedInputChange = useDebouncedCallback((e) => {
      onDebouncedChange(e)
    }, 300)

    const classnames = classNames(styles.MagicSearch, {
      [styles.Active]: active
    })

    return (
      <div className={classnames}>
        <SearchIcon className={styles.SearchIcon} />
        <input
          value={input}
          onChange={(e) => {
            setInput(e.target.value)
            debouncedInputChange(e.target.value)
          }}
          {...props}
        />
        {input !== '' && (
          <CloseIcon
            width={16}
            height={16}
            className={styles.CloseIcon}
            onClick={() => {
              setInput('')
              debouncedInputChange('')
            }}
          />
        )}
      </div>
    )
  }
)
export default MagicSearch
