import type React from 'react'
import BaseSideBarComponent from 'src/components/BaseSideBarComponent/BaseSideBarComponent'
import WithdrawIcon from 'src/assets/images/svg/WithdrawIcon'
import { colors } from 'src/assets/scss/themes'
import Input from 'src/ui/Input/Input'
import InfoIcon from 'src/assets/images/svg/InfoIcon'
import Select from 'src/components/Select/Select'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { useStore } from 'src/models/Store'
import { type SingleValue } from 'react-select'
import { useNavigate, useParams } from 'react-router-dom'
import Button, { EButtonType } from 'src/ui/Button'
import { useState } from 'react'
import ModalOTPInput from 'src/components/ModalOTPInput/ModalOTPInput'
import { type UseParamsWithdraw } from '../WithdrawPage'
import { observer } from 'mobx-react'
import NoTFASideBarComponent from 'src/components/EnableTFASidebarComponent/EnableTFASidebarComponent'

interface WithdrawAddressesAddNewProps {
  navigateAfterSubmit: string
}

type AddNewAddressFormInputs = {
  name: string
  slug: string
  tag: string
  network: SingleValue<{ label: string; value: number }>
  tfa_code: string
}

const WithdrawAddressesAddNew: React.FC<WithdrawAddressesAddNewProps> =
  observer(({ navigateAfterSubmit }) => {
    const [formStep, setFormStep] = useState<'addNew' | '2fa'>('addNew')
    const { initialStore, withdrawAddressesStore, userStore } = useStore()
    const navigate = useNavigate()

    const {
      control,
      register,
      handleSubmit,
      formState: {
        isSubmitting,
        errors: { slug, name }
      },
      trigger
    } = useForm<AddNewAddressFormInputs>()

    // TODO: INIT data is not loading fast enough, we need it to load before everything
    const options = initialStore.networks.items.map((item) => {
      return {
        label: item.name,
        value: item.id
      }
    })

    const onSubmit: SubmitHandler<AddNewAddressFormInputs> = async ({
      name,
      network,
      slug,
      tfa_code,
      tag
    }) => {
      if (network !== null) {
        const response = await withdrawAddressesStore.addWithdrawAddress({
          name,
          network_id: network.value,
          slug,
          tag,
          tfa_code
        })
        if (response !== undefined) {
          navigate(navigateAfterSubmit)
        }
      }
    }

    const renderFormStep = (): JSX.Element => {
      if (formStep === 'addNew') {
        return (
          <div className="WithdrawPage">
            <h3>Add new wallet</h3>
            <Input
              label="Wallet Name"
              {...register('name', {
                required: true,
                validate: (value) => {
                  const isUniqueName =
                    withdrawAddressesStore.withdrawAddresses.find(
                      (address) => address.name === value
                    )
                  if (isUniqueName !== undefined) {
                    return 'Cannot add addresses with the same names, please enter a unique name for your address.'
                  }
                }
              })}
            />
            <div className="WithdrawPage-FormErrors">{name?.message}</div>
            <Input
              label="Wallet Address"
              {...register('slug', {
                required: true,
                validate: (value) => {
                  const isAddressAlreadyAdded =
                    withdrawAddressesStore.withdrawAddresses.find(
                      (address) => address.slug === value
                    )
                  if (isAddressAlreadyAdded !== undefined) {
                    return 'Address is already added, please add a new unique address.'
                  }
                }
              })}
            />
            <Input label="Memo (optional)" {...register('tag')} />
            <div className="WithdrawPage-FormErrors">{slug?.message}</div>
            <div className="WithdrawPage-Warning">
              <InfoIcon
                fillbackground={colors.error}
                fillinside={colors.white}
              />
              <span>
                Please be careful when choosing a network. Withdraw to a wallet
                with the wrong network, the funds will be lost.
              </span>
            </div>
            {/* // TODO: Move this to it's own components, seems to be used a lot */}
            <Controller
              control={control}
              name="network"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={options}
                  defaultValue={value}
                  placeholder="Select network"
                  onChange={onChange}
                />
              )}
            />
            <div className="WithdrawPage-ButtonGroup">
              <Button
                title="Submit"
                onClick={async () => {
                  const validateBefore2fa = await trigger(
                    ['name', 'slug', 'network'],
                    {
                      shouldFocus: true
                    }
                  )
                  if (validateBefore2fa) {
                    setFormStep('2fa')
                  }
                }}
              />
              <Button
                title="Cancel"
                buttonType={EButtonType.SECONDARY}
                onClick={() => {
                  navigate(-1)
                }}
              />
            </div>
          </div>
        )
      }
      if (formStep === '2fa') {
        return (
          <ModalOTPInput
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
          />
        )
      }
      return <div>Something went wrong, please contact support</div>
    }

    return (
      <BaseSideBarComponent
        headerIcon={<WithdrawIcon stroke={colors.main} />}
        headerTitle={'Add new wallet for Withdraw'}
      >
        {userStore.user.tfa_enabled ? (
          renderFormStep()
        ) : (
          <NoTFASideBarComponent />
        )}
      </BaseSideBarComponent>
    )
  })
export default WithdrawAddressesAddNew
