import type Store from './Store'
import { autorun, makeAutoObservable, runInAction, when } from 'mobx'
import FiltersStore from './FiltersStore'
import PaginationStore from './PaginationStore'
import { type BalanceItem } from './BalancesStore'
import { type BasePaginationRequest } from './FetchModel'

export type AddressesPageFilters = {
  page: number
  page_size?: number
  slug?: string
  network?: string
  foreign_id?: string
  address_type?: string
  merchant?: string
  date_range?: string
}

export type TAddressesListItem = {
  id: number
  address: string
  network: string
  foreign_id: string
  is_locked: boolean
}

export type TAddAddressesParams = {
  tag: string
  slug: string
  network_id: number
  name: string
  tfa_code: string
}

export type TDeleteAddressParams = {
  id: number
  tfa_code: number | string
}

export const addressConfigPageFilters = [
  'slug',
  'network',
  'foreign_id',
  'address_type',
  'merchant',
  'date_range',
  'page',
  'page_size',
  'network_id'
] as const

class AddressesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<AddressesStore, 'rootStore'>(this, { rootStore: false })

    this.filters = new FiltersStore<AddressesPageFilters>()
    this.pagination = new PaginationStore()
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(() => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getAddresses({
              ...this.filters.activeFilters,
              ...this.pagination.initialize()
            })
          }
        })
      }
    )
  }

  filters
  pagination: PaginationStore
  list: TAddressesListItem[] = []
  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false

  selectedCoinForWithdraw: BalanceItem | undefined

  setSelectedCoinForWithdraw(coin: BalanceItem): void {
    this.selectedCoinForWithdraw = coin
  }

  async getAddresses(params: AddressesPageFilters): Promise<void> {
    this.isLoading = true
    const response = await this.rootStore.api.get<
      { items: TAddressesListItem[] } & BasePaginationRequest
    >('/jsapi/addresses', params)

    if (response !== undefined) {
      runInAction(() => {
        this.list = response.items
        this.pagination.init(response.page_size, response.total)
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }
}

export default AddressesStore
