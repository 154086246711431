import './Select.scss'
import ReactSelect, {
  type Props,
  type GroupBase,
  type StylesConfig,
  components,
  type DropdownIndicatorProps
} from 'react-select'
import type StateManagedSelect from 'react-select'
import DropdownIndicatorImage from '../../assets/images/svg/DropdownCarrotIcon.svg'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    /**
     * Should be used if only an image has to be displayed
     */
    small?: boolean
    transparent?: true
  }
}

const Select: StateManagedSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  const baseCustomStyles: StylesConfig<Option, IsMulti, Group> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: 60,
      backgroundColor: 'var(--color-background)',
      borderColor: state.isFocused
        ? 'var(--color-main)'
        : 'var(--color-background)',
      textAlign: 'left',
      paddingLeft: state.selectProps.small === true ? 10 : 20,
      ':hover': {
        borderColor: 'var(--color-main)',
        cursor: 'pointer'
      },
      boxShadow: 'initial'
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isFocused ? 'var(--color-main)' : '',
      padding: state.selectProps.small === true ? 0 : 8,
      marginRight: '10px',
      ':hover': {
        color: 'var(--color-main)',
        fill: 'var(--color-main)'
      }
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'var(--color-primary700)',
      ':hover': {}
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      margin: 10,
      backgroundColor: 'var(--color-background)',
      border: state.isSelected
        ? '1px solid var(--color-main)'
        : '1px solid var(--color-black)',
      width: 'initial',
      borderRadius: 10,
      paddingBlock: 5,
      display: 'flex',
      gap: 15,

      ':hover': {
        color: 'var(--color-main)',
        cursor: 'pointer'
      }
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'var(--color-white)'
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: 'var(--color-white)'
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      display: state.selectProps.small === true ? 'none' : 'initial'
    })
  }

  const transparentCustomStyles: StylesConfig<Option, IsMulti, Group> = {
    ...baseCustomStyles,
    control: (baseStyles, state) => ({
      ...baseStyles,
      height: 60,
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? 'var(--color-main)' : 'white)',
      textAlign: 'left',
      paddingLeft: state.selectProps.small === true ? 10 : 20,
      ':hover': {
        borderColor: 'var(--color-main)',
        cursor: 'pointer'
      },
      boxShadow: 'initial'
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isFocused ? 'var(--color-main)' : '',
      padding: state.selectProps.small === true ? 0 : 8,
      marginRight: '10px',
      ':hover': {
        color: 'var(--color-main)',
        fill: 'var(--color-main)'
      }
    }),
    indicatorSeparator: (baseStyles, state) => ({
      display: 'none'
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: 'var(--color-white)'
    })
  }

  // React select custom dropdown icon
  const DropdownIndicator:
    | React.FC<DropdownIndicatorProps<Option, IsMulti, Group>>
    | null
    | undefined = (props): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorImage />
      </components.DropdownIndicator>
    )
  }

  return (
    <div className={`Select${props.small === true ? '__small' : ''}`}>
      <ReactSelect
        placeholder="Select"
        {...props}
        classNamePrefix={'Select'}
        styles={props.transparent ? transparentCustomStyles : baseCustomStyles}
        components={{ DropdownIndicator, ...props.components }}
        menuPlacement="auto"
      />
    </div>
  )
}

export default Select
