import type React from 'react'
import { observer } from 'mobx-react'
import './RedirectPaymentsPage.scss'
import { useParams } from 'react-router-dom'
import CitronLogoYellow from 'src/assets/images/CitronLogoYellow.svg'
import CopyTextComponent from 'src/ui/CopyTextComponent/CopyTextComponent'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import { useStore } from 'src/models/Store'
import { useEffect } from 'react'
import Preloader from 'src/ui/Preloader'
import { colors } from 'src/assets/scss/themes'
import { useTimer } from 'react-timer-hook'
import AlertIcon from 'src/assets/images/svg/AlertIcon'
import { QRCodeSVG } from 'qrcode.react'

const RedirectPaymentsPage: React.FC = observer(() => {
  // ID cannot be null, route is strict
  const { id } = useParams() as { id: string }
  const { redirectPaymentsStore } = useStore()
  const { payment, paymentStatus } = redirectPaymentsStore
  const time = new Date()
  time.setSeconds(time.getSeconds() + 10) // 10 seconds timer

  const { totalSeconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      window.location.replace(payment?.return_url as string)
    }
  })

  useEffect(() => {
    // First get on page load
    void redirectPaymentsStore.getPaymentDeposit(id)

    // then get each 10 seconds
    const poolPaymentDeposit = setInterval(
      // eslint-disable-next-line no-void
      () => void redirectPaymentsStore.getPaymentDeposit(id),
      10000
    )
    return () => {
      clearInterval(poolPaymentDeposit)
    }
  }, [])

  useEffect(() => {
    if (paymentStatus === 'Pending') {
      moveToSpecificStep(1)
    }
    if (paymentStatus === 'Success') {
      moveToSpecificStep(2)
      // After timeout redirect back to external url
      restart(time)
    }
  }, [paymentStatus])

  const { currentStepElement, moveToSpecificStep } = useFormStepper([
    <>
      {payment !== null ? (
        <>
          <h4>Please make a deposit. Scan QR code or copy the address.</h4>
          <ol>
            <li>
              <span>Processing currency: </span>
              <span>{payment.currency}</span>
            </li>
            <li>
              <span>Wallet Network: </span>
              <span>{payment.network_name}</span>
            </li>
          </ol>
          <div className="RedirectPaymentsPage-Info">
            <AlertIcon fill={colors.error} />
            <p style={{ color: colors.main }}>
              Please be careful when choosing a network. Top up to a wallet with
              the wrong network, the funds will be lost.
            </p>
          </div>
          <div className="RedirectPaymentsPage-QRWrapper">
            <QRCodeSVG
              value={payment.payment_attributes.address}
              imageSettings={{
                src: `/assets/coins/${payment.currency}.svg`,
                height: 40,
                width: 40,
                excavate: true
              }}
              size={250}
            />
          </div>
          <p>You can copy deposit address</p>
          <CopyTextComponent text={payment.payment_attributes.address} />
          {payment.payment_attributes.memo !== undefined && (
            <>
              <p>You can copy memo</p>
              <CopyTextComponent text={payment.payment_attributes.memo} />
            </>
          )}
        </>
      ) : (
        <Preloader />
      )}
    </>,
    <>
      <h4>
        We`ve received your deposit and waiting for the confirmation in the
        blockchain
      </h4>
      <ol>
        <li>
          <span>Transaction amount: </span>
          <span>
            {payment?.income?.amount} {payment?.currency}
          </span>
        </li>
        <li>
          <span>Current status: </span>
          <span>
            <span className="status status-neutral"></span>
            Confirming ({payment?.income?.confirmations}/
            {payment?.income?.expected_confirmations})
          </span>
        </li>
      </ol>
    </>,
    <>
      <h4 style={{ color: colors.main }}>Your payment was successful</h4>
      <ol>
        <li>
          <span>Transaction amount: </span>
          <span>
            {payment?.income?.amount} {payment?.currency}
          </span>
        </li>
        <li>
          <span>Current status: </span>
          <span>
            <span className="status status-success"></span>
            Success
          </span>
        </li>
      </ol>
      <p>This window will be closed after {totalSeconds} seconds</p>
    </>
  ])
  return (
    <div className="RedirectPaymentsPage">
      <CitronLogoYellow />
      {currentStepElement}
    </div>
  )
})
export default RedirectPaymentsPage
