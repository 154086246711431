import styles from './Dropdown.module.scss'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { type PropsWithChildren, type ReactNode, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'

type DropdownProps = {
  dropdownToggle: ReactNode
} & PropsWithChildren

export const Dropdown = observer(
  ({ dropdownToggle, children }: DropdownProps) => {
    const ref = useDetectClickOutside({
      onTriggered: () => {
        setDropdownOpen(false)
      }
    })
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const dropdownClassnames = classNames(styles.Content, {
      [styles.Visible]: dropdownOpen
    })

    return (
      <div className={styles.Dropdown} ref={ref}>
        <div
          className={styles.Toggle}
          onClick={() => {
            setDropdownOpen((prevState) => !prevState)
          }}
        >
          {dropdownToggle}
        </div>
        <div className={dropdownClassnames}>
          <ul className={styles.List}>
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child as React.ReactElement<any>, {
                  onClick: (): void => {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (child.props.onClick) {
                      child.props.onClick()
                    }
                    setDropdownOpen(false)
                  }
                })
              }
              return child
            })}
          </ul>
        </div>
      </div>
    )
  }
)
