import type React from 'react'
import type PaginationStore from '../../models/PaginationStore'
import ReactPaginate from 'react-paginate'
import { observer } from 'mobx-react'
import { Dropdown, Stack } from 'react-bootstrap'
import styles from './Pagination.module.scss'
import TextComponent from '../TextComponent/TextComponent'
import { PinIcon } from 'src/assets/svg'
import classNames from 'classnames'
import { action } from 'mobx'

export type TPaginationProps = {
  pagination: PaginationStore
  align?: 'left' | 'center' | 'right'
}

const PaginationComponent: React.FC<TPaginationProps> = observer(
  ({ pagination, align = 'right' }) => {
    const classnames = classNames(styles.Pagination, styles[align])

    return (
      <div className={classnames}>
        <ReactPaginate
          onPageChange={(event) => {
            // React paginate counts pages from 0, but BE starts with 1
            const pageToChange = event.selected + 1
            pagination.setPage(pageToChange)
          }}
          className={styles.Paginate}
          pageRangeDisplayed={5}
          pageCount={pagination.pagesLength}
          forcePage={pagination.currentPage - 1}
          renderOnZeroPageCount={null}
          breakLabel="..."
          nextLabel={
            <>
              Next
              <PinIcon />
            </>
          }
          nextLinkClassName={styles.NextBackButtons}
          previousLinkClassName={styles.NextBackButtons}
          breakLinkClassName={styles.PageItem}
          previousLabel={
            <>
              <PinIcon style={{ transform: 'rotate(180deg)' }} />
              Prev
            </>
          }
          pageLinkClassName={styles.PageItem}
          activeLinkClassName={styles.ActivePage}
        />
        <div className={styles.RowsPerPage}>
          <TextComponent body3>Rows per page</TextComponent>
          <Dropdown>
            <Dropdown.Toggle
              variant="dark"
              id="dropdown-basic"
              size={'sm'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {pagination.itemsPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item
                onClick={() => {
                  pagination.resetWithParams(10)
                }}
              >
                10
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  pagination.resetWithParams(25)
                }}
              >
                25
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  pagination.resetWithParams(50)
                }}
              >
                50
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    )
  }
)

export default PaginationComponent
