import { NavLink, type To } from 'react-router-dom'
import styles from './NavButton.module.scss'
import classNames from 'classnames'
type NavButtonProps = {
  title: string
  link: To
  active?: boolean
  end?: boolean
  onClick?: () => void
  icon?: React.ReactNode
}

const NavButton = ({
  link,
  title,
  active,
  end,
  onClick,
  icon
}: NavButtonProps) => {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        isActive
          ? classNames(styles.NavButton, styles.Active)
          : styles.NavButton
      }
      end={end}
      onClick={onClick}
    >
      {icon} {title}
    </NavLink>
  )
}
export default NavButton
