import type React from 'react'
import { useEffect } from 'react'
import './BalanceReports.scss'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import useSideBarModal from 'src/hooks/useSideBarModal'
import Payin from 'src/assets/images/svg/Payin.svg'

import Button, { EButtonSize } from 'src/ui/Button'
import CreateBalanceReport from './CreateBalanceReport'
import ArrowIcon from 'src/assets/images/svg/ArrowIcon'
import { colors } from 'src/assets/scss/themes'
import { observer } from 'mobx-react'
import { useStore } from 'src/models/Store'
import type { TableColumn } from 'react-data-table-component'
import type { IReport } from 'src/models/ReportsStore'
import { formatDate } from 'src/utils/helpers/formatDate'

const columns: Array<TableColumn<IReport>> = [
  {
    name: 'Created',
    selector: (row) => formatDate(new Date(row.date_created)),
    id: 1
  },
  {
    name: 'Action',
    cell: (row) => downloadReportButton(row),
    button: true,
    minWidth: '140px',
    id: 3
  }
]

const downloadReportButton = (report: IReport): JSX.Element => {
  const { reportsStore } = useStore()

  if (report.status === 'created') {
    return (
      <Button
        onClick={async () => {
          await reportsStore.generateReport(report.id, 'balances')
        }}
        icon={<Payin stroke={colors.bg} />}
        title={'Generate'}
        size={EButtonSize.SM}
      />
    )
  }

  if (report.status !== 'ready') {
    return (
      <Button
        onClick={() => {
          reportsStore.getReportDownloadable(report.id)
        }}
        disabled
        icon={<Payin stroke={colors.bg} />}
        title={'Processing'}
        size={EButtonSize.SM}
      />
    )
  }
  return (
    <Button
      onClick={() => {
        reportsStore.getReportDownloadable(report.id)
      }}
      icon={<Payin stroke={colors.bg} />}
      title={report.status === 'ready' ? 'Download' : 'Processing'}
      size={EButtonSize.SM}
    />
  )
}

const BalanceReports = observer(() => {
  const { openModal } = useSideBarModal()
  const { reportsStore } = useStore()
  const { reports } = reportsStore

  useEffect(() => {
    reportsStore.filters.setFilter('type', 'balances')
  }, [])

  return (
    <div className="BalanceReports">
      <div className="BalanceReports-Actions">
        <Button
          title="Create report"
          onClick={() => {
            openModal(
              <CreateBalanceReport />,
              <ArrowIcon stroke={colors.main} />,
              'Create balance report'
            )
          }}
        />
      </div>
      <BaseDataTable
        columns={columns}
        data={reports}
        paginationLogic={reportsStore.pagination}
      />
    </div>
  )
})
export default BalanceReports
