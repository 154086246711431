import { observer } from 'mobx-react'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import { type TableColumn } from 'react-data-table-component'
import { type TAddressesListItem } from 'src/models/AddressesStore'
import { useStore } from 'src/models/Store'
import Search, { SearchFilter } from 'src/ui/Filters/Search'

const columns: Array<TableColumn<TAddressesListItem>> = [
  {
    name: 'ID',
    selector: (row) => row.id,
    allowOverflow: false,
    center: true,
    maxWidth: '200px',
    grow: 0,
    style: {
      backgroundColor: '#2E2E2E',
      borderRadius: 10,
      height: 25,
      alignSelf: 'center'
    },
    id: 1
  },
  {
    name: 'Network',
    cell: (row) => displayNetworkWithImage(row.network),
    id: 2
  },
  { name: 'Address', selector: (row) => row.address, id: 3 },
  { name: 'Foreign ID', selector: (row) => row.foreign_id, id: 4 }
]

const displayNetworkWithImage = (networkName: string): JSX.Element => {
  return (
    <div>
      <img
        src={`/assets/networks/${networkName.toLowerCase()}.svg`}
        style={{ marginRight: 10 }}
      />
      {networkName}
    </div>
  )
}

const SystemAddressesPage = observer(() => {
  const { addressesStore } = useStore()

  return (
    <>
      <div className="AddressesPage">
        <div className="AddressesPage-Actions">
          <Search
            title="Address"
            onDebouncedChange={(e) => {
              addressesStore.pagination.resetPage()
              addressesStore.filters.setFilter('slug', e)
            }}
          />
          <SearchFilter
            filtersStore={addressesStore.filters}
            filterNameFromFilterConfig="foreign_id"
            title="Foreign ID"
            onChange={() => {
              addressesStore.pagination.resetPage()
            }}
          />
        </div>
        <BaseDataTable
          columns={columns}
          data={addressesStore.list}
          paginationLogic={addressesStore.pagination}
        />
      </div>
    </>
  )
})

export default SystemAddressesPage
