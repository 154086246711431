import type Store from './Store'
import { makeAutoObservable, observable, runInAction, toJS, when } from 'mobx'

export type TMerchant = {
  name: string
  id: number
}

class MerchantStore {
  constructor(private readonly rootStore: Store) {
    makeAutoObservable<MerchantStore, 'rootStore'>(this, {
      rootStore: false,
      merchants: observable.deep
    })
    when(
      () => this.rootStore.authStore.isAuth,
      () => {
        if (this.merchants.length > 0) {
          void this.setCurrentMerchant(this.merchants[0])
        }
      }
    )
  }

  merchants: TMerchant[] = []
  currentMerchant: TMerchant = { name: '...', id: NaN }

  setMerchants(merchants: TMerchant[]): void {
    this.merchants = merchants
  }

  async setCurrentMerchant(current: TMerchant): Promise<void> {
    if (this.currentMerchant.id !== current.id) {
      await this.rootStore.api.post('/jsapi/change-merchant', {
        merchant_id: current.id
      })
      runInAction(() => {
        this.currentMerchant = current
      })
    }
  }
}

export default MerchantStore
