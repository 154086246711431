import Preloader from 'src/ui/Preloader'
import styles from './ComponentLoading.module.scss'

const ComponentLoading = () => {
  return (
    <div className={styles.ComponentLoading}>
      <Preloader />
    </div>
  )
}
export default ComponentLoading
