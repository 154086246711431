import { observer } from 'mobx-react'
import { Outlet } from 'react-router-dom'
import NavButton from 'src/components/Navigation/NavButton/NavButton'
import NavigationWrapper from 'src/components/Navigation/NavigationWrapper/NavigationWrapper'

const AddressesPage = observer(() => {
  return (
    <>
      <div className="ReportsPage">
        <NavigationWrapper>
          <NavButton link="/addresses" title="System Addresses" end />
          <NavButton
            link="/addresses/customAddresses"
            title="Custom Addresses"
          />
          <NavButton link="/addresses/fiat-addresses" title="Bank Accounts" />
        </NavigationWrapper>
      </div>
      <Outlet />
    </>
  )
})

export default AddressesPage
